import React from 'react';
import './pre-text.css'

function TextPreview(props){
  // console.log(props)
  let data=''
  if(props.data){
    data=props.data.text.replace(/\n/g,"<br/>")
  }
  return (
    <>
    {
      data.length>0 ? 
      <div className="preview-text-bg" dangerouslySetInnerHTML={{ __html: data }}>
      </div>
      :null
    }
    </>
    
  )

}

export default TextPreview