import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { UserContext } from '../../store/context'

function TableHeader(props){
  const {userInfo}=useContext(UserContext)

  let history=useHistory()
  const checkStatus=()=>{
    console.log(props.type)
    if(userInfo.roleId!==1&&userInfo.roleId!==3&&props.type==='robot'){
      message.error('请先开通会员再进行操作')
      return false;
    }
    history.push(props.route)
  }
  return(
    <div className="table-head">
      <Button type="primary" onClick={checkStatus}>
        <PlusOutlined />{props.tips}
      </Button>
    </div>
  )
}
export default TableHeader;