import React from 'react';
import './pre-feedcard.css'

function FeedCardPreview(props){
  // console.log(props)
  let secData=[]
  let fristData=[]
  if(props.data.links){
    fristData=props.data.links.slice(0,1)
    secData=props.data.links.slice(1)
  }


  return(
    <div className="preview-feedcard-bg">
      {
        fristData.map((item)=>{
          return(
            <div key="feedCard-frist" className="feedCard-frist preview-cnum" onClick={()=>{window.open(item.messageURL);}}>
              <img className="img" alt={item.title}  src={item.picURL}></img>
              <div className="mask"></div>
              <div className="title">
                {item.title}
              </div>
            </div>
          )
        })
      }
    
    
      <div className="not-frist">
        {/* <div className="feedCard-second preview-cnum">
          <div className="title">时代的火车向前开时代的火车向前开时代的火车向前开时代的火车向前开时代的火车向前开时代的火车向前开时代的火车向前开</div>
          <div className="img">
            <img className="img" src="https://gw.alicdn.com/tfs/TB1ayl9mpYqK1RjSZLeXXbXppXa-170-62.png"></img>
          </div>
        </div> */}
        {
          secData.map((item,index)=>{
            return(
              <div key={`sec${index}`}  className="feedCard-second preview-cnum" onClick={()=>{window.open(item.messageURL);}}>
                <div className="title">{item.title}</div>
                <div className="img">
                  <img className="img" alt={item.title} src={item.picURL}></img>
                </div>
              </div>
            )
          })
        }
        
        
      </div>
    </div>
  )
}
export default FeedCardPreview