import React,{useState} from 'react';
import {Steps, Button, Form, Input,Result,Card,PageHeader,Image} from 'antd'
// import {MobileOutlined,UserOutlined,LockOutlined,EyeTwoTone,EyeInvisibleOutlined,KeyOutlined,InfoCircleOutlined} from '@ant-design/icons'
// import jiaqian from '../../img/jiaqian.png'
import { useHistory } from 'react-router-dom'
// import webhook from '../../img/webhook.png'
import { addRbot } from '../../api/index'
import '../../css/robotAdd.css'

function RobotAdd(){
  let history=useHistory()
  const { Step } = Steps;
  const { TextArea }=Input
  const [ loading,setLoading ] = useState(false);

  const [ current,setCurrent ] = useState(0);
  const [ helpInfo,setHelpInfo ] = useState(0);


  const onFinish = async(values) => {
    setLoading(true)
    // console.log(values)
    await addRbot({
      robotsName: values.name,
      webhook: values.webhook,
      secret: values.secret,
      remark: values.mark,
    }).then((res)=>{
      if(res.success){
        setCurrent(1)
      }
      // console.log(res)
    })
    setLoading(false)

  };
  const help=[
    <div>
      <h2>在添加机器人前你需要做好以下准备:</h2>
      <h4>一、确保已经在群内增加一个自定义机器人</h4>
      <h4>二、确保该自定义机器人的【消息推送】功能保持【开启】状态</h4>
      <h4>三、确保该自定义机器人的【安全设置】仅勾选【加签】</h4>
      <br />
      <h2>如何添加自定义机器人？</h2>
      <h4>【群设置】-【智能群助手】-【添加机器人】-【自定义】</h4>
      <br />
      <h2>如何查看/设置群内已经存在的自定义机器人？</h2>
      <h4>【群设置】-【智能群助手】-选取已存在的机器人</h4>
    </div>,
    <div>
      <h2>如何查看WebHook？</h2>
      <h4>【群设置】-【智能群助手】-选取已存在的机器人</h4>
      <br />
      <h4>示意图(点击放大)：</h4>
      <Image
        src="https://static.dingtalk.com/media/lALPDh0cNYFVUnDNBQDNB4A_1920_1280.png"
      />
    </div>,
    <div>
      <h2>如何查看加签？</h2>
      <h4>【群设置】-【智能群助手】-选取已存在的机器人</h4>
      <br />
      <h4>示意图(点击放大)：</h4>
      <Image
        src="https://static.dingtalk.com/media/lALPDhYBODfIUnHNBQDNB4A_1920_1280.png"
      />
    </div>,
  ]
  const steps = [
    {
      title: '信息录入',
      content: 
        <div className="input-view">
          <div className="info">
            <Card title="信息填写">
              <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
                <Form.Item name="name" label="机器人名称(建议使用组别命名)："
                  rules={[
                    { required: true ,message:'机器人名称不能为空'},
                    ]}>
                  <Input maxLength={15} onFocus={()=>setHelpInfo(0)} />
                </Form.Item>
                <Form.Item name="webhook" label="WebHook：" rules={[
                  { required: true ,message:'WebHook不能为空'},
                  { type: 'url' ,message:'请输入正确的WebHook'},
                  { min: 114 ,message:'WebHook长度为114位'},
                  ]}>
                  <TextArea maxLength={114} autoSize onFocus={()=>setHelpInfo(1)}/>
                </Form.Item>
                <Form.Item name="secret" label="加签：" rules={[
                  { required: true ,message:'加签不能为空'},
                  { len: 67 ,message:'加签长度为67位'},

                  ]}>
                  <TextArea maxLength={67} autoSize onFocus={()=>setHelpInfo(2)} />
                </Form.Item>
                <Form.Item name="mark" label="备注：" rules={[

                  ]}>
                    <Input maxLength={20} onFocus={()=>setHelpInfo(0)} />
                </Form.Item>
                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit">
                    下一步
                  </Button>
                </Form.Item>
              </Form>
            </Card>
            
          </div>
          <div className="help-info">
            <Card title="帮助信息" style={{height:'100%'}}>
              {help[helpInfo]}
            </Card>
          </div>
        </div>
    },
    {
      title: '完成',
      content: 
        <Result
        status="success"
        title="添加成功"
        subTitle="机器人添加成了哦～"
        extra={[
          <Button key="login" type="primary" onClick={()=>{history.replace('/system/robot/list')}}>
            去查看
          </Button>,
        ]}
      />
      ,
    },
  ];

  return(
    <div className="robot-add">
      <div className="add-view">
      <PageHeader
        style={{padding:0}}
        className="site-page-header"
        onBack={() => history.replace('/system/robot/list')}
        title="添加机器人"

      />
        <div className="view-header">
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className="view-content">
          {steps[current].content}
        </div>
        
      </div>
    </div>
  )
  
}
export default RobotAdd