import React,{useState,useContext, useMemo} from 'react';
// import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Spin, Card, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { UserContext } from '../../store/context';
import { putUserInfo } from '../../store/pubtype';
import '../../css/login.css'
import {login} from '../../api/index'
import Cookie from 'js-cookie'
import Logo from '../../img/light.svg'
import LoginHeader from '../../img/login-header.png'
import Column from 'antd/lib/table/Column';
// import sha256 from 'crypto-js/sha256';

function Login(){
  // let history = useHistory();
  // const {userInfo} = useContext(UserContent);
  const {dispatch:userDispatch} = useContext(UserContext);
  const [loading,setLoading]= useState(false)

  const onFinish = async(values) => {
    setLoading(true);
    await login({
      username:values.username,
      password:values.password
    }).then((res)=>{
      setLoading(false);
      if(res.success){
        Cookie.set('userAuth',res.data.token)
        userDispatch({type:putUserInfo,value:res.data.loginSysUserVo})
        // history.replace('/dashboard')
      }
      
    });
    // console.log('Received values of form: ', values);

  };
  return (
    <Row style={{height:'100%'}}>
      <Col 
        span={14} 
        style={{
          background:'-webkit-linear-gradient(0deg,#376be6 0%,#6470ef 100%)',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          padding:'0 1.5rem'
        }}>
          
        <h1 style={{color:'white',fontSize:'2.125rem',fontWeight:'400'}}>“相伴数载，重新出发”</h1>
      </Col>
      <Col
        span={10}
        style={{
          padding:'3rem 4rem',
          display:'flex',
          flexDirection:'Column'
        }}
      >
        {/* <img src={LoginHeader}/> */}
      <div className="lazayoa-login">
        <div style={{margin:'2rem 0',display:'flex',alignItems:'center'}}>
          <img style={{marginRight:'20px'}} width="50" src={Logo}></img>
          <h1 style={{margin:'4rem 0',color:'#2D89D5'}}>LazyOA</h1>
        </div>
        
        <Spin spinning={loading}>
          
          <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名' }]}
              >
                <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="密码"
                />
              </Form.Item>
              {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox size="large">保持登录</Checkbox>
                </Form.Item>

                <a href="#" className="login-form-forgot">
                  忘记密码
                </a>
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" className="login-form-button">
                  立即登录
                </Button>
              </Form.Item>
              <a href="#/regist">立即注册</a> <a href="#/forget">找回密码</a>
            </Form>
        </Spin>
        <div className="copyright">Copyright © 2020 LazyOA <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备15015075号-4</a></div>
      </div>
      </Col>
    </Row>
    
    
  );
};
export default Login