import React,{useState,useEffect} from 'react';
import {Steps, Button, Form, Input,Tooltip,Spin,Result, message} from 'antd'
import { useHistory } from 'react-router-dom'
import {MobileOutlined,UserOutlined,LockOutlined,EyeTwoTone,EyeInvisibleOutlined,KeyOutlined,InfoCircleOutlined} from '@ant-design/icons'
import '../../css/regist.css'
import {getSmsCode,forget} from '../../api/index'
import Logo from '../../img/light.svg'
function Forget(){
  const { Step } = Steps;
  let history=useHistory()

  const [ current,setCurrent ] = useState(0);
  const [ loading,setLoading ] = useState(false);
  const [ countTips,setcountTips ] = useState('获取验证码');
  const [ countDown,setcountDown ] = useState(60);
  const [ isSend, setIsSend ] = useState(false)
  const [ spin,setspin ] = useState(false);
  const [ phone,setPhone ] = useState('');

  useEffect(()=>{
    let timer=null;
    if(isSend){
      timer=setInterval(() => {
        setcountDown(n => {
        setcountTips(`${n-1}秒后重新获取`)
          if (n === 1) {
            setIsSend(false)
            clearInterval(timer)
            setcountTips('获取验证码')
            setcountDown(60)
          }
         return n - 1
        });
      }, 1000);
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer)
    };
  },[isSend])

  const onFinish = async(values) => {
    setspin(true);
    console.log(values)
    if(values.password===values.repassword){
      await forget({
        newPassword:values.password,
        phone:values.phone,
        code:values.vercode
      }).then((res)=>{
        if(res.success){
          setCurrent(1)
        }
      })
    }else{
      message.error('密码不一致，请重新输入')
    }

    setspin(false);
  };

  const getCode= async ()=>{
    if(!isSend){
      setLoading(true)
      await getSmsCode({phone:phone}).then((res)=>{
        if(res.success){
          setIsSend(true);
          setcountTips(`${countDown}秒后重新获取`)
        }
      });
      setLoading(false)
    }
  }

  const steps = [
    {
      title: '找回密码',
      content: 
      <div className="regist-phone">
        <Form
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            rules={[
              { required: true, message: '请输入密码' },
              { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/, message: '密码至少包含（数字、英文），长度6-16' },
            ]}
          >
            <Input.Password
              maxLength="16"
              placeholder="请输入密码"
              prefix={<LockOutlined />}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            name="repassword"
            rules={[
              { required: true, message: '请输入密码' },
              { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/, message: '密码至少包含（数字、英文），长度6-16' },
            ]}
          >
            <Input.Password
              maxLength="16"
              placeholder="请再次输入密码"
              prefix={<LockOutlined />}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: '请输入11位手机号' },
              { pattern: /^1[3456789]\d{9}$/, message: '请输入11位手机号' },
            ]}
          >
            <Input 
              maxLength="11" 
              placeholder="请输入11位手机号" 
              prefix={<MobileOutlined />} 
              suffix={
                <Tooltip title="请输入11位手机号">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
              onChange={
                (e)=>{
                  // console.log(e.target.value)
                  setPhone(e.target.value);
                }
              }
            />
          </Form.Item>
          <Form.Item
            name="vercode"
            rules={[
              { required: true, message: '请输入6位验证码' },
              { pattern: /^\d{6}$/, message: '请输入6位验证码' }
            ]}
          >
            <Input.Search 
              maxLength="6" 
              disabled={!(/^1[3456789]\d{9}$/).test(phone) ? true : false}
              placeholder="请输入验证码" 
              prefix={<KeyOutlined />} 
              enterButton={countTips} 
              loading={loading} 
              onSearch={getCode} 
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" id="submit" htmlType="submit">
              下一步
            </Button>
          </Form.Item>
        </Form>

        {/* <Button type="primary" htmlType="submit" onClick={() => setCurrent(current+1)}>
              下一步
        </Button> */}
      </div>
      ,
    },
    {
      title: '完成',
      content: 
        <Result
        status="success"
        title="找回密码成功"
        extra={[
          <Button key="login" onClick={()=>{history.push('/login')}} type="primary">
            去登录
          </Button>,
        ]}
      />
      ,
    },
  ];

  
  
  return(
    <div className="regist-main">
      <div className="Header">
        <div style={{height:'100%',display:'flex',alignItems:'center'}}>
          <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>{window.location.href="https://www.lazyoa.com"}}>
            <img style={{margin:'0 20px'}} width="50" src={Logo}></img>
            <h1 style={{color:'#2D89D5',margin:'0'}}>LazyOA</h1>
          </div>
        </div>
      </div>
      <div className="content">
      <Spin tip="Loading..." spinning={spin}>
        <div className="card">
          <div className="title">
            欢迎使用 LazyOA
          </div>
          <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps[current].content}
        </div>
        {/* <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => setCurrent(current+1)}>
              下一步
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              完成
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => setCurrent(current-1)}>
              上一步
            </Button>
          )}
        </div> */}
        </div>
        </Spin>
      </div>
    </div>
  )
}

export default Forget