import React, { useMemo, useState } from 'react';
import { Table, Spin, Tooltip, Space, Popconfirm, Badge, message, Button } from 'antd';
import {getRobotList, delRbot,restartRobot} from '../../../api/index'
import RobotDrawer from '../../../compotent/Robot/Drawer/RobotDrawer';
import { RobotContext } from '../../../store/context'
import { render } from '@testing-library/react';
function RobotListCom(props){
  const [loading,setLoading]=useState(false)
  const [data,setData]=useState([])
  const [visible,setVisible]=useState(false)
  const [type,setType]=useState('')
  const [showType,setShowType]=useState('nomal')


  const [robotContent,setRobotContent]=useState(null)
  // let nowType='edit'



  const color=[
    'red',
    'green',
    'blue'
  ]
  const statustips=[
    '异常',
    '正常',
    '停用',
  ]
  

  let columns=[];
  let rowSelection=null

  const onSelectChange = selectedRowKeys => {
    props.selAction(selectedRowKeys)

    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    
  };
  switch (showType) {
    case 'sel':
      rowSelection={
        selectedRowKeys:props.sel,
        onChange: onSelectChange,
      }

      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          width:100,
          ellipsis: true,
        },
        {
          title: '名称',
          dataIndex: 'robotsName',
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width:80,
          ellipsis: true,
          render: tips => {
            return(
              <Tooltip placement="topLeft" title={`备注:\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align:'right',
          width:80,
          render: (text, record)=>{
            return (
              <a onClick={()=>Robotdetail(text,record)}>详情</a>
            )
          }
        },
      ];
      break;
    default:

      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          width:200
        },
        {
          title: '名称',
          dataIndex: 'robotsName',
          ellipsis: true,
        },
        {
          title: 'WebHook',
          dataIndex: 'webhook',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`WebHook:\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '签名',
          dataIndex: 'secret',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`签名:\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`备注:\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '状态',
          dataIndex: 'state',
          render: (status,record)=>{
            if(status==1){
              return <Badge color={color[status]} text={statustips[status]} />
            }else{
              return(
                <>
                  <Badge color={color[status]} text={statustips[status]} />
                  <Button type="link" onClick={async()=>{
                    // console.log(record)
                    setLoading(true)
                    await restartRobot({id:record.id}).then((res)=>{
                      if(res.success){
                        message.success('启用成功')
                      }
                    })
                    getList()


                  }}>启用</Button>
                </>
              )
            }
            
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align:'right',
          render: (text, record)=>{
            return (
              <Space>
                <a onClick={()=>Robotdetail(text,record)}>详情</a>
                <a onClick={()=>editRobot(text,record)}>编辑</a>
                <Popconfirm
                  placement="topRight"
                  overlayStyle={{minWidth:'200px'}}
                  title="你确定要删除这个机器人吗？"
                  onConfirm={()=>robotDel(record.id)}
                  // onCancel={cancel}
                  cancelText="取消"
                  okText="确定"
                >
                  <a className="robot-del">删除</a>
                </Popconfirm>
              </Space>
            )
          }
        },
      ];
      break;
  }

  const editRobot=(text,record)=>{
    setType('edit')
    setRobotContent(record)
    setVisible(true)
  }

  const Robotdetail=(text,record)=>{
    setType('detail')
    setRobotContent(record)
    setVisible(true)
  }




  const getList=async()=>{
    setLoading(true)
    await getRobotList().then((res)=>{
      if(res.success){
        setData(res.data)
      }
    })
    setLoading(false)
  }

  const robotDel=async(id)=>{
    setLoading(true)
    await delRbot({id:id}).then((res)=>{
      if(res.success){
        message.success('删除成功')
      }
      getList()
    })
  }

  
  useMemo(()=>{
    if(props.type){
      setShowType(props.type)
    }
    if(!visible){
      getList()
    }
  }, [showType==='nomal' && visible])

  return(
    <Spin spinning={loading}>
      <div className="table-data">
      {
          visible ?
          <RobotContext.Provider value={robotContent}>
            <RobotDrawer pop={{
                type:type,
                state:[visible,setVisible],
              }
              }  />
          </RobotContext.Provider>
          :null
        }
        <Table rowSelection={rowSelection} pagination={showType==='sel' ? {defaultPageSize:5} : null} className="table-data" columns={columns} dataSource={data} rowKey="id" />

      </div>
    </Spin>
  )
}

export default RobotListCom;