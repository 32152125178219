import React, { useContext } from 'react';
import { Avatar, Popover,Menu, Divider, Dropdown } from 'antd';
import {
  UserOutlined,
  PoweroffOutlined
} from '@ant-design/icons';
import '../css/header.css'
import Cookie from 'js-cookie'
import { loginout } from '../api/index'
import { useHistory } from 'react-router-dom';
import { outUserInfo } from '../store/pubtype';
import { UserContext } from '../store/context';

function NavHeader(){
  let history=useHistory()
  const {userInfo}=useContext(UserContext)
  const {dispatch:userDispatch} = useContext(UserContext);
  const menu = (
    <Menu className="avatar-menu" style={{border:'none'}}>
      <Menu.Item onClick={async()=>{
        await loginout().then((res)=>{
        })
        // Cookie.set('userAuth',res.data.token)
        Cookie.remove('userAuth')
        userDispatch({type:outUserInfo,value:null})
        history.replace('/login')
      }}>
        <PoweroffOutlined /> 退出登录
      </Menu.Item>
    </Menu>
  );
  // const menu = (
  //   <Menu className="avatar-menu" style={{border:'none'}}>
  //     <Menu.Item>
  //         <UserOutlined />个人设置
  //     </Menu.Item>
  //     {/* <Menu.Divider/> */}
  //     <Menu.Item onClick={async()=>{
  //       await loginout().then((res)=>{
  //       })
  //       // Cookie.set('userAuth',res.data.token)
  //       userDispatch({type:outUserInfo,value:null})
  //       Cookie.remove('userAuth')
  //       history.replace('/login')
  //     }}>
  //       <PoweroffOutlined />退出登录
  //     </Menu.Item>
  //   </Menu>
  // );
  // const avatarContent = (
  //   <Dropdown overlay={menu} placement="bottomCenter">
  //     {userInfo.username}
  //   </Dropdown>
  //   // <div className="avatar-content">
  //   //   <h5 className="username">i枕头战士</h5>
  //   //   <div className="info-label">
  //   //     <label>会员状态：</label>
  //   //     <span>已开通</span>
  //   //   </div>
  //   //   <div className="info-label">
  //   //     <label>会员到期时间：</label>
  //   //     <span>2020-11-11</span>
  //   //   </div>
  //   //   <Divider/>
  //   //   {menu}
  //   // </div>
  // );
  return(
    <div className="user-avatar">
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <div style={{cursor:'pointer'}}>
          <span style={{marginLeft:'5px',marginRight:'5px'}}>{userInfo.username}</span><Avatar style={{ backgroundColor: '#F3B900', verticalAlign: 'middle' }} size="small">
        {userInfo.username.substring(0,1)}
      </Avatar>
        </div>
      </Dropdown>
    </div>
  )
}
export default NavHeader