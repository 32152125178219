import React from 'react';
import { Redirect, Route } from 'react-router-dom'


function AuthRoute(props) {

  const {
    userInfo,
    redirect,
    role,
    children,
    auth,
    ...other
  } = props

  if (!auth) {
    if (userInfo.hasOwnProperty('roleCode')) {
      if (props.path !== '/login' && props.path !== '/regist' && props.path !== '/forget') {
        return <Route {...other} />
      } else {
        return <Redirect to="/" />
      }
    } else {
      if (props.path === '/login' || props.path === '/regist' || props.path === '/forget') {
        return <Route {...other} />
      } else {
        return <Redirect to="/login" exact from={props.path} />
      }
    }
  } else {
    if (userInfo.hasOwnProperty('roleCode')) {
      if (userInfo.roleCode && role.indexOf(userInfo.roleCode) > -1) {
        if (redirect) {
          return <Redirect to={redirect} from={props.path} />
        } else {
          return <Route {...other} />
        }
      } else {
        return <Redirect to="/system/404" />
      }
    } else {
      return <Redirect to="/login" exact from={props.path} />
    }
  }



  // if(!auth){
  //   if(userInfo.hasOwnProperty('roleCode')){
  //     if(props.path!=='/login'&&props.path!=='/regist'){
  //       return <Route {...other} />
  //     }else{
  //       return <Redirect to="/" />
  //     }
  //   }else{
  //     if(redirect){
  //       return <Redirect to={redirect} />
  //     }
  //     return <Route {...other} />
  //   }
  // }else{
  //   if(userInfo.hasOwnProperty('roleCode')){
  //     console.log(1)
  //     if(userInfo.roleCode&&role.indexOf(userInfo.roleCode)>-1){
  //       console.log(1.1)
  //       if(redirect){
  //         return <Redirect to={redirect} from={props.path} />
  //       }else{
  //         return <Route {...other} />
  //       }

  //     }else{
  //       console.log(1.2)

  //       return <Redirect to="/404" />
  //     }
  //   }else{
  //     console.log(2)

  //     return <Redirect to="/login" exact from={props.path} />
  //   }
  // }
}

export default AuthRoute;