import React, { useMemo, useState } from 'react';
import { Table, Spin, Tooltip, Space, Popconfirm, message, Badge } from 'antd';
import '../../css/robotList.css'
import {getTaskList,delTask} from '../../api/index'
import { useHistory } from 'react-router-dom';
import TableHeader from '../../compotent/Robot/TableHeader';
import moment from 'moment';



function RobotTask(){
  let history=useHistory()
  const [data,setData]=useState(null)
  const [loading,setLoading]=useState(false)


  const getList=async()=>{
    setLoading(true)
    await getTaskList().then((res)=>{
      if(res.success){
        let data=[]
        let alread=[]
        res.data.forEach(item => {
          if(alread.indexOf(item.groupId)===-1){
            let temp={}
            temp.id=item.groupId
            temp.contentId=item.contentId
            temp.contentName=item.contentName
            temp.lastTime=item.createTime
            temp.taskDetail=[]
            temp.robotDetail=[]
            temp.timeNum=0
            alread.push(item.groupId)
            for(let i=0;i<res.data.length;i++){
              if(res.data[i].groupId===temp.id){
                if(temp.robotDetail.indexOf(res.data[i].robotId)===-1){
                  temp.robotDetail.push(res.data[i].robotId)
                }
                temp.taskDetail.push({
                  id:res.data[i].id,
                  robotId:res.data[i].robotId,
                  name:res.data[i].robotsName,
                  taskType:res.data[i].taskType,
                  where1:res.data[i].where1,
                  whereHour:res.data[i].whereHour,
                  whereMin:res.data[i].whereMin
                })
              }
            }
            data.push(temp)
          }
        });
        // console.log(data)
        setData(data)
      }
    })
    setLoading(false)
  }

  const Taskdel=async(id)=>{
    setLoading(true)
    await delTask({
      groupId:id
    }).then((res)=>{
      if(res.success){
        message.success('删除成功')
      }else{
        message.error('删除失败')

      }
    })
    getList()
  }
  

  useMemo(getList,[])
  const columns = [
    {
      title: '任务组ID',
      dataIndex: 'id',
      width:150
    },
    {
      title: '消息简介',
      dataIndex: 'contentName',
      ellipsis: true,
    },
    {
      title: '时间段数量',
      align:'center',
      width:120,
      dataIndex: 'timeNum',
      render: (text, record) => {
        return record.taskDetail.length/record.robotDetail.length
      },
    },
    {
      title: '机器人数量',
      align:'center',
      width:120,
      dataIndex: 'robotNum',
      render: (text, record) => {
        return record.robotDetail.length
      },
    },
    {
      title: '最后修改时间',
      dataIndex: 'lastTime',
      ellipsis: true,
      render: tips => {
        return (
          <Tooltip placement="topLeft" title={`签名:\n${tips}`}>
            {tips}
          </Tooltip>
        )
      },
    },
    {
      title: '状态',
      align:'center',
      width:100,
      dataIndex: 'state',
      render: (text, record) =>{
        return <Badge color="green" text="正常" />
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      align:'right',
      width:100,
      render: (text, record)=>{
        return (
          <Space>
            <a onClick={()=>{
              // console.log(record)
              let temp=record.taskDetail.concat()
              temp.splice(record.taskDetail.length/record.robotDetail.length,record.taskDetail.length)
              // console.log(temp)
              // console.log(record.taskDetail)
              history.push({pathname:'/system/robot/taskadd',query:{
                type:'edit',
                groupId:record.id,
                contentId:record.contentId,
                robotIds:record.robotDetail,
                rateData:temp,
              }})
            }}>编辑</a>
            <Popconfirm
              placement="topRight"
              overlayStyle={{minWidth:'200px'}}
              title="你确定要删除这个任务吗？"
              onConfirm={()=>Taskdel(record.id)}
                
              // onCancel={cancel}
              cancelText="取消"
              okText="确定"
            >
              <a className="robot-del">删除</a>
            </Popconfirm>
          </Space>
        )
      }
    },
  ];

  const secondColumns = [
    {
      title: '任务ID',
      dataIndex: 'id',
      width:100
    },
    {
      title: '机器人ID',
      align:'center',
      dataIndex: 'robotId',
      width:100
    },
    {
      title: '机器人名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '发送频率',
      dataIndex: 'taskType',
      render: (text, record) => {
        let temp;
        switch (text) {
          case 'day':
            temp='每天'
            break;
          case 'week':
            temp='每周'
            switch (record.where1) {
              case 1:
                temp=temp+'一'
                break;
              case 2:
                temp=temp+'二'
                break;
              case 3:
                temp=temp+'三'
                break;
              case 4:
                temp=temp+'四'
                break;
              case 5:
                temp=temp+'五'
                break;
              case 6:
                temp=temp+'六'
                break;
              case 7:
                temp=temp+'日'
                break;
              default:
                break;
            }
            break;
          case 'month':
            temp='每月'+record.where1+'号'
            break;
          default:
            break;
        }
        return temp
      },
    },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      ellipsis: true,
      render: (text, record)=>{
        return moment(`${record.whereHour}:${record.whereMin}`, 'HH:mm').format("HH:mm")
      }
    },
  ];
  return(
    <div className="table-view">
      <TableHeader
        type="task"
        route='/system/robot/taskadd'
        tips="添加新的任务"
      />
      <Spin spinning={loading}>
        <Table 
          className="table-data" 
          columns={columns} 
          dataSource={data} 
          rowKey="id" 
          expandable={{
            expandedRowRender: record => (
              <Table columns={secondColumns} dataSource={record.taskDetail} rowKey="id" />
            ),
            // rowExpandable: record => record.name !== 'Not Expandable',
          }}
        />
      </Spin>
      </div>
  )
}
export default RobotTask