import React,{ useContext} from 'react';
import { Drawer } from 'antd';
import { RobotContentContext } from '../../../store/context'
// import { editRbot } from '../../../api/index'
import '../../../css/ContentDrawer.css'
import FeedCardPreview from '../Preview/FeedCard'
import TextPreview from '../Preview/Text'
import MarkdownPreview from '../Preview/Markdown'



function ContentDrawer(props){
  const [visible,setVisible]=props.pop.state //刷新状态
  // const [loading,setLoading]=useState(false) //刷新状态

  // console.log(props)

  let contentDetail=useContext(RobotContentContext);
  // let previewContent=null;
  // console.log(contentDetail)
  



  // const { TextArea }=Input

  // const edit=async(val)=>{
  //   await editRbot({
  //     id:contentDetail.id,
  //     robotsName: val.name,
  //     webhook: val.webhook,
  //     secret: val.secret,
  //     remark: val.mark,
  //   }).then((res)=>{
  //     if(res.success){
  //       onClose()
  //       message.success('机器人信息修改成功')
  //     }
  //     console.log(res)
  //   })
  // }


  const onClose=()=>{
    setVisible(false)
  }

  return(
    <Drawer
      width={400}
      title="消息预览"
      placement="right"
      className="content-preview"
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {
        contentDetail.msgtype==='feedCard' ?
          <FeedCardPreview data={contentDetail}></FeedCardPreview> :
        contentDetail.msgtype==='text' ?
          <TextPreview data={contentDetail}></TextPreview> :<MarkdownPreview data={contentDetail}></MarkdownPreview>

      }
      
      {/* {previewContent} */}
    </Drawer>
  )
}
export default ContentDrawer