import React, { useMemo, useState } from 'react';
import {Steps, Button, Form, Input,Spin,Result,Card,PageHeader,Checkbox,Row,Col,Modal, message, Table, Radio, TimePicker, DatePicker} from 'antd'
import '../../css/contentAdd.css'
import { useHistory } from 'react-router-dom';
import {addTask,updateTask} from '../../api/index'

import ContentListCom from '../../compotent/Robot/List/ContentListCom';
import RobotListCom from '../../compotent/Robot/List/RobotListCom';
import moment from 'moment';

function TaskAdd(props){

  const [ current,setCurrent ] = useState(0);
  const [ loading,setLoading ] = useState(false);
  const [ contentId,setContentId ]= useState(null)
  const [ robotIds,setRobotIds ]= useState([])
  const [ groupId,setGroupId ]= useState(null)

  const [ rate,setRate ]= useState('day')
  const [ rateData,setRateData ]= useState([])
  const [ weekChoose,setWeekChoose ]= useState({val:'1',label:'一'})
  useMemo(()=>{
    if(props.location.query){
      if(props.location.query.type==='edit'){
        setGroupId(props.location.query.groupId)
        setContentId(props.location.query.contentId)
        setRobotIds(props.location.query.robotIds)
        setRateData(props.location.query.rateData)
      }
    }
  },[])

  const columns=[
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   render: (text, record) => text+1
    // },
    {
      title: '发送周期',
      dataIndex: 'taskType',
      render: (text, record) => {
        // console.log(record)
        let temp;
        switch (text) {
          case 'day':
            temp='每天'
            break;
          case 'week':
            temp='每周'
            switch (record.where1.toString()) {
              case '1':
                temp=temp+'一'
                break;
              case '2':
                temp=temp+'二'
                break;
              case '3':
                temp=temp+'三'
                break;
              case '4':
                temp=temp+'四'
                break;
              case '5':
                temp=temp+'五'
                break;
              case '6':
                temp=temp+'六'
                break;
              case '7':
                temp=temp+'日'
                break;
              default:
                break;
            }
            break;
          case 'month':
            temp='每月'+record.where1+'号'
            break;
          default:
            break;
        }
        return temp
      }
    },
    {
      title: '发送时间',
      dataIndex: 'where1',
      render: (text, record) => {
        return moment(`${record.whereHour}:${record.whereMin}`, 'HH:mm').format("HH:mm")
        // return record.whereHour+':'+record.whereMin
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      align:'right',
      render: (text, record)=>(
        <a onClick={()=>{
          let temp=rateData.concat()
          temp.splice(
            temp.indexOf(
            temp.find(
              function(el){
                return el.id==record.id;
              }
            )),1)
          setRateData(temp);
        }}>删除</a>
      )
    },
  ];

  let history=useHistory()
  const { Step } = Steps;


  const next = async() => {
    switch (current) {
      case 0:
        if(!contentId){
          message.error('请选择消息')
          return null
        }
        break;
      case 1:
        if(rateData.length===0){
          message.error('请添加发送时间')
          return null
        }
        if(robotIds.length===0){
          message.error('请选择机器人')
          return null
        }
        setLoading(true)
        await addTask({
          groupId,
          robotIds,
          contentId,
          timeGroups:rateData
        }).then((res)=>{
          if(!res.success){
            message.error(res.message)
            return null
          }
        })
        setLoading(false)
        break;
      default:
        break;
    }
    setCurrent(current+1)
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const WeekChange=(value)=> {
    let temp=''
    switch (value.target.value) {
      case '1':
        temp='一'
        break;
      case '2':
        temp='二'
        break;
      case '3':
        temp='三'
        break;
      case '4':
        temp='四'
        break;
      case '5':
        temp='五'
        break;
      case '6':
        temp='六'
        break;
      case '7':
        temp='日'
        break;
      default:
        break;
    }
    setWeekChoose({val:value.target.value,label:temp})
    // console.log('week: ', value);
  }


  const onFieldsChange=(changedFields, allFields)=>{
    // console.log(allFields)
    if(allFields[0].value!==rate){
      setRate(allFields[0].value)
    }
  }

  const onFinish = async(val) => {
    // console.log(values)
    // console.log(val)
    // console.log(moment('0:0', 'HH:mm').format("HH:mm"))
    if(val.rateData){
      let data=rateData.concat()
      let temp={};
      switch (val.sendRate) {
        case 'day':
          temp.where1=null
          break;
        case 'week':
          temp.where1=weekChoose.val
          break;
        case 'month':
          temp.where1=val.rateData.format('D')
          break;
        default:
          break;
      }
      temp.id=data.length
      temp.taskType=val.sendRate
      temp.whereHour=val.rateData.format('HH')
      temp.whereMin=val.rateData.format('mm')
      data.push(temp)
      // console.log(data)
      setRateData(data)
    }else{
      message.error('请先填写发送时间再进行添加')
      return false;
    }
    // console.log(val)
  };
  const steps = [
    {
      title: '选择消息',
      content: 
        <ContentListCom type="sel" selAction={setContentId} sel={contentId} />
    },
    {
      title: '设置发送任务',
      content: 

      <Row gutter={16}>
        <Col span={9}>
          <Card title="机器人选择">
            <RobotListCom type="sel" selAction={setRobotIds} sel={robotIds} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="添加发送时间">
            <Form layout="vertical" name="nest-messages" onFinish={onFinish} onFieldsChange={onFieldsChange} >
              <Form.Item
                name="sendRate"
                label="发送频率"
                initialValue={rate}
                rules={[
                  {required:true,message:''}
                ]}
              >
                <Radio.Group
                  buttonStyle="solid"
                >
                  <Radio.Button value="day">每天</Radio.Button>
                  <Radio.Button value="week">每周</Radio.Button>
                  <Radio.Button value="month">每月</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="rateData"
              >
                {
                  rate==='day' ? 
                    <TimePicker format="每天 HH:mm" style={{minWidth:'200px'}}/>
                  : rate==='week' ?
                    <TimePicker
                      style={{minWidth:'200px'}}
                      format={`每周${weekChoose.label} HH:mm`}
                      renderExtraFooter={() => (
                        <div style={{minWidth:'200px',paddingTop:'10px'}}>
                          <Radio.Group
                            defaultValue={weekChoose.val}
                            buttonStyle="solid"
                            onChange={WeekChange}
                          >
                            <Radio.Button value="1">一</Radio.Button>
                            <Radio.Button value="2">二</Radio.Button>
                            <Radio.Button value="3">三</Radio.Button>
                            <Radio.Button value="4">四</Radio.Button>
                            <Radio.Button value="5">五</Radio.Button>
                            <Radio.Button value="6">六</Radio.Button>
                            <Radio.Button value="7">日</Radio.Button>
                          </Radio.Group>
                        </div>

                      )}
                    />
                  :
                    <DatePicker showTime format="每月D日 HH:mm" style={{minWidth:'200px'}} />
                }
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  添加
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={9}>
          <Card title="时间管理">
            <Table columns={columns} dataSource={rateData} rowKey="id" />
          </Card>
        </Col>
        
      </Row>
    },
    {
      title: '完成',
      content: 
        <Result
        status="success"
        title="添加成功"
        subTitle="消息添加成了哦～"
        extra={[
          <Button key="login" type="primary" onClick={()=>{history.replace('/system/robot/task')}}>
            去查看
          </Button>,
        ]}
      />
      ,
    },
  ];

  return(
    <div className="robot-add">
      <div className="add-view">
        
      <PageHeader
        style={{padding:0}}
        className="site-page-header"
        onBack={() => history.goBack()}
        title="任务设置"
      />
        <div className="view-header">
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className="view-content">
          {steps[current].content}
        </div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button loading={loading} type="primary" onClick={() => next()}>
              下一步
            </Button>
          )}
          {current > 0 && current<steps.length-1 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              上一步
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
export default TaskAdd