import React, { useMemo, useState } from 'react';
import { Pagination, Card, List, Spin } from 'antd';
import {getPageList} from '../../api/index'

function Order(){
  const [ loading,setLoading ]=useState(false)
  const [ data,setData]=useState([])
  const [ pageIndex,setpageIndex ]=useState(1)
  const [ pageSize,setpageSize ]=useState(10)
  const [ current,setCurrent ]=useState(1)

  const getList=async(pageIndex,pageSize)=>{
    setLoading(true)
    await getPageList({pageIndex,pageSize}).then((res)=>{
      console.log(res)
      if(res.success){
        setCurrent(res.data.total)
        setData(res.data.records)
      }
    })
    setLoading(false)
  }


  const onChange = (page, pageSize) => {
    console.log(page, pageSize);
    getList(page, pageSize)
    setpageIndex(page)
    setpageSize(page)
  };


  useMemo(()=>{
    getList(1,10)
  },[])
  // data = [
  //   {
  //     title: 'Ant Design Title 1',
  //   },
  //   {
  //     title: 'Ant Design Title 2',
  //   },
  //   {
  //     title: 'Ant Design Title 3',
  //   },
  //   {
  //     title: 'Ant Design Title 4',
  //   },
  // ];
  return(
    <div style={{
      height: '100%',overflow: 'auto',position: 'relative',padding: '16px 16px 200px 16px'
    }}>
      <Spin spinning={loading}>
        <Card title="订单列表" bordered={false}>
          <List
            itemLayout="vertical"
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  // title={item.orderTitle}
                  title={`订单编号：${item.tradeOrderId}`}

                  description={`${item.orderTitle}　创建时间：${item.createTime} `}
                />
                <List.Item.Meta
                  description={`金额：${item.totalFee}　订单状态：${item.status===1 ? '交易成功' :'交易关闭'} `}
                />
              </List.Item>
            )}
          />
          <div style={{margin:'20px 0',textAlign:'right'}}>
            <Pagination onChange={onChange} defaultCurrent={'1'} total={current} />
          </div>
        </Card>
      </Spin>
    </div>
  )
}
export default Order;