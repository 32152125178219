import React from 'react';
import '../../css/robotList.css'
import TableHeader from '../../compotent/Robot/TableHeader';
import RobotListCom from '../../compotent/Robot/List/RobotListCom';


function RobotList(){

  return(
    <div className="table-view">
      <TableHeader
          type="robot"
        route="/system/robot/robotadd"
        tips="新增机器人"
      />
      <RobotListCom />
    </div>
  )
}
export default RobotList
