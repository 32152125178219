import React,{useContext, useMemo, useState} from 'react';
import {HashRouter as Router,Redirect,Switch,Link} from 'react-router-dom'
import authRoutes from '../../routes/authRoutes';
import AuthRoute from '../../routes/AuthRoute'
import NavHeader from '../../compotent/header'
import { Layout, Menu } from 'antd';
import Logo from '../../img/logo1.svg'

import '../../css/system.css'
// import {
//   HomeOutlined,
//   AndroidOutlined,
//   ContainerOutlined
// } from '@ant-design/icons';
import { UserContext } from '../../store/context';

function LayoutUI(props){
  // console.log(props)
  const { Header, Sider, Content } = Layout;
  const { userInfo }=useContext(UserContext)
  const [ nowSel,setNowsel ]=useState('0')
  let pageroute=null;
  for(let i=0;i<authRoutes.length;i++){
    if(authRoutes[i].path==='/system'){
      pageroute=authRoutes[i].children;
      break;
    }
  }
  // console.log(pageroute)

  const getNowpath=()=>{
    // console.log(111)
    pageroute.map((route,index)=>{
      if(!route.hide){
        if(props.location.pathname.indexOf(route.path)>-1){
          setNowsel(index.toString())
        }
      }
      return true;
    })
    
  }
  // useEffect(()=>{
  //   getNowpath();
  // },[props])

  useMemo(getNowpath,[props])
  
  return(
    <Layout className="system-container">
      <Sider defaultCollapsed collapsedWidth={48} className="nav-sider">
        <Menu
          className="left-nav"
          // defaultSelectedKeys={[nowSel]}
          selectedKeys={[nowSel]}
          theme="dark"
        >
          <div className="logo">
            <img width="36"  src={Logo} />
          </div>
          {
            pageroute.map((route,index)=>{
              if(!route.hide){
                if(route.path!=='/system/dashboard'&&route.hasOwnProperty('role')&&route.role.indexOf(userInfo.roleCode)===-1){
                  return true;
                }
                return(
                  <Menu.Item key={index.toString()} icon={<route.icon style={{fontSize:'20px',lineHeight:'20px'}} />}>
                    <Link to={route.path}>
                      {route.name}
                    </Link>
                  </Menu.Item>
                )
              }
              return true;
            })
          }
        </Menu>
      </Sider>
      <Layout>
        <Header className="header-nav">
          <NavHeader/>
        </Header>
        <Content className="content-container">
          <Router>
            <Switch>
              {
                pageroute.map((route)=><AuthRoute key={route.path} userInfo={userInfo} {...route} /> )
                // authRoutes[2].children.map((route)=>{
                //   return <AuthRoute key={route.path} userInfo={userInfo} {...route} />
                // })
              }
              <Redirect to="/system/dashboard" />
            </Switch>
          </Router>
        </Content>
      </Layout>
    </Layout>
  )
}
export default LayoutUI