import {
  HomeOutlined,
  AndroidOutlined,
  PayCircleOutlined
} from '@ant-design/icons';


import Login from "../view/Login/Login";
import Register from "../view/Regist/Regist";
import Dashboard from '../view/Dashboard/Dashboard'
import LayoutUI from "../view/Layout/system";
import Robot from "../view/Robot/Robot";
import RobotList from "../view/Robot/RobotList";
import RobotContent from "../view/Robot/RobotContent";
import RobotTask from "../view/Robot/RobotTask";
import RobotAdd from "../view/Robot/RobotAdd";
import PageUndefind from '../view/404/404';
import ContentType from '../view/Robot/ContentType';
import ContentAdd from '../view/Robot/ContentAdd';
import TaskAdd from '../view/Robot/TaskAdd';
import Order from '../view/Order/Order';
import Forget from '../view/Regist/Forget';
const authRoutes=[
  
  {
    path:'/login',
    component:Login,
    exact:true,
    auth:false
  },
  {
    path:'/regist',
    component:Register,
    exact:true,
    auth:false
  },
  {
    path:'/forget',
    component:Forget,
    exact:true,
    auth:false
  },
  {
    path:'/system',
    component:LayoutUI,
    exact:false,
    // role:['user','admin'],
    auth:false,
    children:[
      {
        name:'首页',
        path:'/system/dashboard',
        icon:HomeOutlined,
        component:Dashboard,
        exact:true,
        role:['user','admin','vip_user'],
        auth:true
      },
      {
        path:'/system/robot/robotadd',
        component:RobotAdd,
        exact:true,
        hide:true,
        role:['user','admin','vip_user'],
        auth:true
      },
      {
        path:'/system/robot/contenttype',
        component:ContentType,
        exact:true,
        hide:true,
        role:['user','admin','vip_user'],
        auth:true
      },
      {
        path:'/system/robot/contentadd',
        component:ContentAdd,
        exact:true,
        hide:true,
        role:['user','admin','vip_user'],
        auth:true
      },
      {
        path:'/system/robot/taskadd',
        component:TaskAdd,
        exact:true,
        hide:true,
        role:['user','admin','vip_user'],
        auth:true
      },
      {
        name:'机器人',
        path:'/system/robot',
        icon:AndroidOutlined,
        component:Robot,
        exact:false,
        role:['user','admin','vip_user'],
        auth:true,
        children:[
          {
            name:'机器人列表',
            path:'/system/robot/list',
            component:RobotList,
            exact:true,
            role:['user','admin','vip_user'],
            auth:true
          },
          {
            name:'消息列表',
            path:'/system/robot/content',
            component:RobotContent,
            exact:true,
            role:['user','admin','vip_user'],
            auth:true
          },
          {
            name:'发送任务列表',
            path:'/system/robot/task',
            component:RobotTask,
            exact:true,
            role:['user','admin','vip_user'],
            auth:true
          },
          
        ]
      },
      {
        name:'我的订单',
        path:'/system/order',
        icon:PayCircleOutlined,
        component:Order,
        exact:true,
        role:['user','admin','vip_user'],
        auth:true,
      },
      {
        path:'/system/404',
        component:PageUndefind,
        hide:true,
        exact:true,
        auth:false
      },
    ]
  },
]
export default authRoutes