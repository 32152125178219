import React, { useContext,useEffect,useMemo, useState } from 'react';
import { PageHeader, Card, Button, Avatar, Statistic, Tooltip, Result, Modal, Table, Badge, Row, Col } from 'antd'

import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../store/context';
import { hupiPay, getSendRecord } from '../../api/index'



import '../../css/dashboard.css'
function Dashboard(){

  const color=[
    'green',
    'red',
  ]
  const statustips=[
    '成功',
    '失败',
  ]
  const {userInfo}=useContext(UserContext)
  const [ data,setData ]=useState([])
  const [ totals,setTotals ]=useState(1)
  const [ loading,setLoading ]=useState(false)
  const [ payType,setPayType ]=useState(1)
  let temptype=0;


  const getRecord=async(pageIndex,pageSize)=>{
    setLoading(true)
    await getSendRecord({pageIndex,pageSize}).then((res)=>{
      if(res.success){
        setTotals(res.data.total)
        setData(res.data.records)
      }
    })
    setLoading(false)
  }

  useEffect(()=>{
    temptype=payType
    // console.log(payType);
  },[payType])

  useMemo(()=>{
    getRecord(1,10)
  },[])
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '任务ID',
      dataIndex: 'taskId',
    },
    {
      title: '消息简介',
      dataIndex: 'taskName',
    },
    {
      title: '发送时间',
      dataIndex: 'createTime',
    },
    {
      title: '执行状态',
      dataIndex: 'errcode',
      render:(text,record)=>{
        let temp=''
        switch(text){
          case 0:
            temp=<Badge color={color[0]} text={statustips[0]} />
            break;
          default:
            let reasontips=''
            switch (record.errmsg) {
              case 'only group admin can @ All':
                reasontips='群内开启了【仅群主和群管理员可@所有人】'
                break;
              case 'bot is stopped':
                reasontips='机器人【推送】被关闭'
                break;
              case '系统繁忙':
              reasontips='钉钉系统繁忙，即将自动重试发送，可关注群内是否发送成功，若发送不成功可再次手动操作发送'
                break;
              case 'keywords not in content, more: [https://ding-doc.dingtalk.com/doc#/serverapi2/qf2nxq]':
                reasontips='关键词与机器人设置的关键词不匹配'
                break;
              default:
                reasontips='未知，请联系管理员确认'
                break;
            }
            temp=<div>
              <Tooltip title={reasontips}>
                <Badge color={color[1]} text={statustips[1]} />
                <QuestionCircleOutlined style={{marginLeft:'10px'}} />
              </Tooltip>
            </div>
            break;
        }
        return(
          temp
        )
      }
    },

  ];

  const getvip=()=>{
    let num=1;
    const modal=Modal.info({
      maskClosable:true,
      width:600,
      title:'购买会员',
      content:(
        <>
          <p>购买/续费会员可拥有3个机器人配额，用于绑定机器人</p>
          <p>注意：如果会员到期请在续费后检查机器人状态，选择是否启用</p>
          <Row gutter={16}>
            <Col span={8}>
              <Card title="月付" hoverable onClick={()=>{gopay(1)}}>
                <p>价格</p>
                ¥4.99元/月
              </Card>
            </Col>
            <Col span={8}>
              <Card title="半年付" hoverable onClick={()=>{gopay(6)}}>
                <p>半年付享95折</p>
                折后每月4.74元
              </Card>
            </Col>
            <Col span={8}>
              <Card title="年付" hoverable onClick={()=>{gopay(12)}}>
                <p>年付享85折</p>
                折后每月4.24元
              </Card>
            </Col>
          </Row>
        </>
      ),
      // okText:'去支付',
      // onOk:()=>{
      //   return new Promise(async(resolve, reject)=>{
      //     await hupiPay({shop_id:1}).then((res)=>{
      //       if(res.success){
      //         window.location.href=res.data
      //       }
      //       resolve()
      //     })
      //   })
      // }
    })
    const gopay=(num)=>{
      {
        return new Promise(async(resolve, reject)=>{
          await hupiPay({shop_id:1,num:num}).then((res)=>{
            if(res.success){
              window.location.href=res.data
            }
            resolve()
          })
        })
      }
    }

  }
  const getmorerobot=()=>{
    Modal.info({
      okText:'去支付',
      maskClosable:true,
      title:'购买配额',
      content:(
        <>
          <p>购买配额</p>
          <p>开通会员后拥有3个配额，若是需要更多配额可以进行购买</p>
          <p>注意：购买配额时会员有效期需要大于31天</p>
          <p>售价：¥1元/个/月</p>
        </>
      ),
      onOk:()=>{
        return new Promise(async(resolve, reject)=>{
          await hupiPay({shop_id:2}).then((res)=>{
            if(res.success){
              window.location.href=res.data
            }
            resolve()
          })
        })
      }
    })
  }
  return(
    <div>
      <PageHeader
        className="content-view"
        ghost={false}
        title="工作台"
        extra={
          <>
            <Button type="primary" onClick={()=>{
              Modal.info({
                title:'为什么要收费？',
                maskClosable:true,
                content:
                <div>
                  <p>LazyOA的前身是机器人群发平台，目的是为了方便小伙伴们群内宣导和传达，减少大家机械的重复劳动。</p>
                  <p>但平台开放使用三年以来，除人工成本外，仅服务器成本三年支出已超过1W+，为了方便大家，故迁移至LazyOA平台，并收取一定费用以维持项目的运营。</p>
                  <p>LazyOA的目的是为了维持项目运营而非盈利，所以会收取一定费用，辛苦大家体谅。接下去我们会开发更多功能来帮助大家减少机械性的劳动。</p>
                </div>
              })
            }}>为什么要收费？</Button>
            <Button onClick={()=>{
              Modal.info({
                title:'联系我们',
                maskClosable:true,
                content:
                <>
                  <Button onClick={()=>{window.open('https://static.dingtalk.com/media/lADPDgQ9vwZ1oP3NBLbNAu4_750_1206.jpg')}}>联系人一</Button>
                  <p></p>
                  <Button onClick={()=>{window.open('https://static.dingtalk.com/media/lADPDh0cNYfdY2jNBkjNAzw_828_1608.jpg')}}>联系人二</Button>
                </>
              })
            }}>联系我们</Button>
          </>
        }
      >
        <div className="dashboard-header">
          <div className="userContent">
            <Avatar
              size={64}
              style={{ backgroundColor: '#F3B900', verticalAlign: 'middle' }}
            >
              {userInfo.username.substring(0,1)}
            </Avatar>
            <div className="info-view">
              <div className="ask">
                Hi，{userInfo.username}，祝你开心每一天！
              </div>
              <div className="role">
                会员状态：
                {
                  userInfo.roleId!==3 ? 
                  <span>未开通<Button type="link" onClick={getvip}>立即开通</Button></span>
                :<span>{userInfo.vipEndTime} 到期<Button type="link" onClick={getvip}>续费</Button></span>
                }
              </div>
            </div>
          </div>
          <div className="robot-info">
          <Statistic 
            title={
              <div>
                <span style={{marginRight:'5px'}}>
                  Robot配额
                </span>
                {
                  userInfo.roleId===3 ?
                    <Button type="link" onClick={getmorerobot}>购买</Button>
                  :
                  <Tooltip title={'开通VIP即可拥有3个机器人配额'}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                }
              </div>
            } 
            value={userInfo.maxRobot} 
            suffix="个" 
          />
  {/* 
          <Card size="small" title="已拥有机器人配额" extra={<a href="#">购买</a>} style={{ width: 200 }}>
            <Statistic value={3} suffix="个" />
          </Card> */}
          </div>
        </div>
        
      </PageHeader>
      {/* <div>
        <Result
          status="403"
          title="建设中"
          subTitle="伴随着头发脱落，脑袋上的灯泡还没亮起来"
        />
      </div> */}


      <div className="table-view" style={{margin:'16px 16px 200px 16px'}}>
        <h1>近期机器人发送日志</h1>
        <div className="table-data">
          <Table
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={data}
            pagination={
              {
                total:totals,
                onChange: (page,pageSize)=>{
                  getRecord(page,pageSize)
                },
              }
            }
          />
        </div>
      </div>
    </div>
    // <div className="dashboard-view">
    //   <div className="content-view">
    //     <Card title="Hi,你好呀，很高兴我们又见面了">
    //       <Card.Grid>
    //         致小伙伴们的一封信
    //       </Card.Grid>
    //       <Card.Grid>
    //         开通会员
    //       </Card.Grid>
    //       <Card.Grid>
    //         购买配额
    //       </Card.Grid>
    //       {/* <Card.Grid>Content</Card.Grid>
    //       <Card.Grid>Content</Card.Grid>
    //       <Card.Grid>Content</Card.Grid> */}
    //     </Card>
    //   </div>
    // </div>
  )
}

export default Dashboard;