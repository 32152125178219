import React,{useContext,useState,useMemo}  from 'react';
import { Menu } from 'antd';
import {MailOutlined} from '@ant-design/icons'

import {Link,Redirect,Switch} from 'react-router-dom'
import authRoutes from '../../routes/authRoutes';
import AuthRoute from '../../routes/AuthRoute'
import { UserContext } from '../../store/context';

import {
  AndroidOutlined,
} from '@ant-design/icons';
import '../../css/robot.css'

function Robot(props){
  const { SubMenu } = Menu;
  const { userInfo }=useContext(UserContext)
  const [pagename,setPagename]=useState('机器人列表')
  const [ nowSel,setNowsel ]=useState('0')
  let pageroute=null;

  for(let i=0;i<authRoutes.length;i++){
    if(authRoutes[i].path==='/system'){
      for(let j=0;j<authRoutes[i].children.length;j++){
        if(authRoutes[i].children[j].path==='/system/robot'){
          pageroute=authRoutes[i].children[j].children;
          break;
        }
      }
      break;
    }
  }
  const getNowpath=()=>{
    // console.log(111)
    pageroute.map((route,index)=>{
      if(props.location.pathname.indexOf(route.path)>-1){
        setPagename(route.name)
        setNowsel(index.toString())
      }
      return true;
    })
  }
  useMemo(getNowpath,[props])


  return(
    <div className="robot-index">
      <div className="robot-nav">
        <div className="robot-title">
          机器人
        </div>
        <Menu className="robot-menu"
          // onSelect={getPageName}
          // defaultSelectedKeys={['0']}
          selectedKeys={[nowSel]}
          defaultOpenKeys={['sub1']}
          mode="inline"
        >
          {/* <Divider /> */}
          <SubMenu key="sub1" icon={<AndroidOutlined />} title="管理">
            {
              pageroute.map((item,index)=>{
                return(
                  <Menu.Item key={index}>
                    <Link to={item.path} replace={true}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                )
                
              })
            }
          </SubMenu>
          <Menu.Item key='sendnow'>
            <Link to={{
              pathname:'/system/robot/contenttype',
              query:{
                type:'sendNow'
              }
            }}  replace={true}>
              <MailOutlined />立即发送
            </Link>
          </Menu.Item>
          
        </Menu>

      </div>
      <div className="robot-content">
        <div className="content-title">
          {pagename}
        </div>
        <div className="content-view">
            <Switch>
              {
                pageroute.map((route)=><AuthRoute key={route.path} userInfo={userInfo} {...route} />)
              }
              <Redirect to="/system/robot/list"/>

              {/* <Redirect to="/404" /> */}
            </Switch>
        </div>
        
      </div>
    </div>
  )
}

export default Robot;