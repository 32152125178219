import React, { useEffect } from 'react';
import './pre-text.css'

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

function MarkdownPreview(props){
  // console.log(props)
  const mdParser=new MarkdownIt(); //markdown-it
  const mdEditor=React.useRef(null); //markdown编辑器
  // MdEditor.unuse(Plugins.Header)
  // MdEditor.unuse(Plugins.FontBold)
  // MdEditor.unuse(Plugins.FontItalic)
  // MdEditor.unuse(Plugins.FontUnderline)
  // MdEditor.unuse(Plugins.FontStrikethrough)
  // MdEditor.unuse(Plugins.ListUnordered)
  // MdEditor.unuse(Plugins.ListOrdered)
  // MdEditor.unuse(Plugins.BlockQuote)
  // MdEditor.unuse(Plugins.BlockWrap)
  // MdEditor.unuse(Plugins.BlockCodeInline)
  // MdEditor.unuse(Plugins.BlockCodeBlock)
  // MdEditor.unuse(Plugins.Table)
  // MdEditor.unuse(Plugins.Image)
  // MdEditor.unuse(Plugins.Link)
  // MdEditor.unuse(Plugins.Clear)
  // MdEditor.unuse(Plugins.Logger)
  // MdEditor.unuse(Plugins.ModeToggle)
  // MdEditor.unuse(Plugins.FullScreen)
  // MdEditor.unuse(Plugins.AutoResize)
  const plugins = [];
  useEffect(()=>{
    mdEditor.current.setText(props.data.text)
  })
  return (
    <>
    <MdEditor
        ref={mdEditor}
        value={props.data.text}
        renderHTML={(text) => mdParser.render(text)}
        // onChange={handleEditorChange}
        style={{borderRadius:"0 10px 10px 10px"}}
        plugins={plugins}
        config={{
          view:{
            menu: false,
            md: false,
            html: true,
            fullScreen: true,
            hideMenu: false,
          }
        }}
      />
    </>
    
  )

}

export default MarkdownPreview