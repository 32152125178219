// import React, { createContext,useReducer } from 'react';

// export const ColorContext =createContext({});

// export const Updata_Color='update'

// const reducer1=(state,action)=>{
//   // switch(action.type){
//   //   case Updata_Color:
//   //     return action.color
//   //   default :
//       return state
//   // }
// }

// export const Color=props=>{
//   const [color,dispatch]=useReducer(reducer1,'blue')
//   return(
//     <ColorContext.Provider value={{User:color,dispatch}}>
//       {props.children}
//     </ColorContext.Provider>
//   )
// }

import React,{ useReducer } from 'react';
import { UserContext } from './context'
import { putUserInfo,outUserInfo } from './pubtype'


const userInfoReducer=(state,action)=>{
  switch(action.type){
    case putUserInfo:
      return action.value
    case outUserInfo:
      return {loginSysUserVo:{}};
    default:
      return state
  }
}

export const UserInfoProvide=props=>{
  const [userInfo,dispatch]=useReducer(userInfoReducer,{loginSysUserVo:{}})
  return(
    <UserContext.Provider value={{userInfo,dispatch}}>
      {props.children}
    </UserContext.Provider>
  )
}