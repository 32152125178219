import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentListCom from '../../compotent/Robot/List/ContentListCom';
import TableHeader from '../../compotent/Robot/TableHeader';

function RobotContent(){

  
  return(
      <div className="table-view">
        
        <TableHeader
          type="content"
          route="/system/robot/contenttype"
          tips="添加新的消息"
        />
        <ContentListCom/>
      </div>
  )
}
export default RobotContent