import request from './axios'

export const registNow=(parmas)=>request('/api/register',parmas,'POST')
export const getSmsCode=(parmas)=>request('/api/alisdk/getSmsCode',parmas)
export const login=(parmas)=>request('/api/login',parmas,'POST')
export const loginout=(parmas)=>request('/api/logout',parmas,'POST')
export const forget=(parmas)=>request('/api/fogetpassword',parmas,'POST')

export const getSysUserInfo=(parmas)=>request('/api/getSysUserInfo',parmas,'POST')



export const getSendRecord=(parmas)=>request('/api/dingtalkRobottaskSendlog/getPageList',parmas,'POST')



export const getRobotList=(parmas)=>request('/api/dingtalkRobot/myrobot',parmas,'POST')
export const addRbot=(parmas)=>request('/api/dingtalkRobot/add',parmas,'POST')
export const delRbot=(parmas)=>request('/api/dingtalkRobot/delrobot',parmas,'POST')
export const editRbot=(parmas)=>request('/api/dingtalkRobot/update',parmas,'POST')
export const restartRobot=(parmas)=>request('/api/dingtalkRobot/restartRobot',parmas,'POST')


export const getContentList=(parmas)=>request('/api/dingtalkRobotContent/myrobotContent',parmas,'POST')
export const addContent=(parmas)=>request('/api/dingtalkRobotContent/add',parmas,'POST')
export const updateContent=(parmas)=>request('/api/dingtalkRobotContent/update',parmas,'POST')
export const delContent=(parmas)=>request('/api/dingtalkRobotContent/delContent',parmas,'POST')

export const sendNow=(parmas)=>request('/api/dingtalkRobotTask/sendsall',parmas,'POST')
export const getTaskList=(parmas)=>request('/api/dingtalkRobotTask/mytaskList',parmas,'POST')
export const addTask=(parmas)=>request('/api/dingtalkRobotTask/add',parmas,'POST')
// export const updateTask=(parmas)=>request('/api/dingtalkRobotTask/update',parmas,'POST')
export const delTask=(parmas)=>request('/api/dingtalkRobotTask/deltask',parmas,'POST')



export const hupiPay=(parmas)=>request('/api/sysVipUser/hupiPay',parmas,'POST')


export const getPageList=(parmas)=>request('/api/sysPayOrders/getPageList',parmas,'POST')







