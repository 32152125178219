import React, { useMemo, useState } from 'react';
import { Card, Col, Row, Button, Image, PageHeader, Switch } from 'antd';
import { useHistory } from 'react-router-dom'
import '../../css/contentType.css'
// import Textimg from '../../img/text.png'
// import Markdown from '../../img/markdown.png'
// import FeedCard from '../../img/feedcard.png'




function ContentType(props){
  let history=useHistory()
  const [chooseType,setChooseType]=useState('add')
  
  useMemo(()=>{
    if(props.location.query){
      setChooseType(props.location.query.type)
    }
  },[props.location.query])
  
  const goAdd=(type)=>{
    history.push({pathname:'/system/robot/contentadd',query:{type:chooseType,msgtype:type}})
  }

  const onChange=(checked)=> {
    if(checked){
      setChooseType('sendNow')
    }else{
      setChooseType('add')
    }
  }
  return(
    <div className="content-add">
        <div className="add-view">
        <PageHeader
          style={{padding:0}}
          className="site-page-header"
          onBack={() => history.replace('/system/robot/content')}
          title="选择消息类型"
          subTitle={
            <>
            <Switch onChange={onChange} checkedChildren="立即发送模式" unCheckedChildren="添加消息模式" defaultChecked={chooseType==='sendNow'} />
            </>
          }
        />
          <div className="list">
            <Row gutter={16}>
              <Col span={6}>
                <Card hoverable>
                  <Image
                    src="https://static.dingtalk.com/media/lALPDh0cNYFUAAPNBQDNB4A_1920_1280.png"
                  />
                  <p>（点击图片预览）</p>
                  <h3>Markdown</h3>
                  <p>支持格式较多，可以插入文字、图片、链接，设置加粗、斜体等</p>
                  <Button type="primary" onClick={()=>goAdd('markdown')}>立即{chooseType==='add' ? '添加' :'发送'}</Button>
                </Card>
              </Col>
              <Col span={6}>
                <Card hoverable>
                  <Image
                    src="https://static.dingtalk.com/media/lALPDgtYvEly_jHNBQDNB4A_1920_1280.png"
                  />
                  <p>（点击图片预览）</p>
                  <h3>FeedCard</h3>
                  <p>支持将消息以文章列表的格式进行展示，获得更好的观感，不支持@所有人</p>
                  <Button type="primary" onClick={()=>goAdd('feedCard')}>立即{chooseType==='add' ? '添加' :'发送'}</Button>
                </Card>
              </Col>
              <Col span={6}>
                <Card hoverable>
                  <Image
                    src="https://static.dingtalk.com/media/lALPDgQ9vv_l_jLNBQDNB4A_1920_1280.png"
                  />
                  <p>（点击图片预览）</p>
                  <h3>Text</h3>
                  <p>仅支持文字形式的内容，适合用于不需要非文本内容点缀的通知</p>
                  <Button type="primary" onClick={()=>goAdd('text')}>立即{chooseType==='add' ? '添加' :'发送'}</Button>
                </Card>
              </Col>
            </Row>
          </div>
          
        </div>
      </div>
  )
}
export default ContentType;