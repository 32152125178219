import React,{ useMemo, useState } from 'react';
import {Steps, Button, Form, Input,Spin,Result,Card,PageHeader,Checkbox,Row,Col,Modal, message, Switch, Table,Tooltip} from 'antd'
import {MinusCircleOutlined, PlusOutlined,QuestionCircleOutlined} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { addContent,updateContent,sendNow, getRobotList } from '../../api/index'
import MarkdownIt from 'markdown-it'
import MdEditor,{ Plugins } from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';
import '../../css/contentAdd.css'

import FeedCardPreview from '../../compotent/Robot/Preview/FeedCard'
import TextPreview from '../../compotent/Robot/Preview/Text'




function ContentAdd(props){
  // console.log(props)

  const { Step } = Steps;
  const { TextArea }=Input
  const [ loading,setLoading ] = useState(false);
  const [ titleboth,setTitleboth ] = useState(false);
  const [ current,setCurrent ] = useState(0);
  const [ atall,setAtall ] = useState(false);
  const [ addType,setAddType ] = useState('');
  const [ actionType,setActionType ] = useState(null);
  const [ robotList,setRobotList ] = useState([]);
  const [ initFormVal,setInitFormVal ] = useState(true);
  const [ switchLoading,setSwitchLoading ] = useState(false);
  const [ checkRobot,setCheckRobot ] = useState([]);

  const [ contentData,setContentData ] = useState({
    atMobiles: null,
    btnOrientation: null,
    btns: null,
    hideAvatar: false,
    id: null,
    isAtall: false,
    links: [{title:'',picURL:'',messageURL:''}],
    msgUrl: null,
    msgtype: "",
    name: "",
    picurlImage: null,
    robotIds: null,
    robot_id: null,
    singleTitle: null,
    singleUrl: null,
    text: "",
    title: "",
  });
  const column=[
    {
      title: 'id',
      dataIndex: 'robotId',
      width:100
    },
    {
      title: '名称',
      dataIndex: 'robotName',
      ellipsis: true,
      render: tips => {
        return (
          <Tooltip title={tips}>
            {tips}
          </Tooltip>
        )
      }
    },
    {
      title: '状态',
      dataIndex: 'reason',
      render:(text,record)=>{
        let temp=''
        switch(record.reason){
          case '发送成功':
            temp='成功'
            break;
          default:
            let reasontips=''
            switch (record.reason) {
              case 'only group admin can @ All':
                reasontips='群内开启了【仅群主和群管理员可@所有人】'
                break;
              case 'bot is stopped':
                reasontips='机器人【推送】被关闭'
                break;
              case '系统繁忙':
              reasontips='钉钉系统繁忙，即将自动重试发送，可关注群内是否发送成功，若发送不成功可再次手动操作发送'
                break;
              case 'keywords not in content, more: [https://ding-doc.dingtalk.com/doc#/serverapi2/qf2nxq]':
                reasontips='关键词与机器人设置的关键词不匹配'
                break;
              default:
                reasontips='未知，请联系管理员确认'
                break;
            }
            temp=<div>
              失败 
              <Tooltip title={reasontips}>
                <QuestionCircleOutlined style={{marginLeft:'10px'}} />
              </Tooltip>
            </div>
            break;
        }
        return(
          temp
        )
      }
    },
  ]
  let history=useHistory()

  const switchChange=async(checked)=> {
    setSwitchLoading(true)
    setTimeout(async()=>{
      if(checked){
        await getRobotList().then((res)=>{
          // console.log(res)
          if(res.success){
            setActionType('sendNow')
            setRobotList(res.data)
          }else{
            setActionType('add')
          }
        })
      }else{
        setActionType('add')
      }
      setSwitchLoading(false)
    },500)
    
  }

  const RobotChange=(checkedValues)=> {
    setCheckRobot(checkedValues)
    // console.log('checked = ', checkedValues);
  }

  const getType=()=>{
    if(props.location.query){
      setAddType(props.location.query.msgtype) //判断需要展示的消息类型
      setActionType(props.location.query.type)// edit:编辑 add:添加 sendNow:立即发送
      if(props.location.query.type==='edit'){
        let temp=props.location.query.data
        if(props.location.query.msgtype==='feedCard'){
          for(let i=0;i<temp.links.length;i++){
            // temp.links[i].messageURL=temp.links[i].messageURL.replace('dingtalk://dingtalkclient/page/link?url=',"")
            temp.links[i].messageURL=decodeURIComponent(temp.links[i].messageURL.replace('dingtalk://dingtalkclient/page/link?url=',""));
          }
        }
        setContentData(temp)
      }else{
        switchChange(props.location.query.type==='sendNow')
      }
    }else{
      history.replace('/system/robot/contenttype')
    }
  }
  useMemo(getType,[])
  


  
  const switchModel=
  <Switch loading={switchLoading} onChange={switchChange} checkedChildren="立即发送模式" unCheckedChildren="添加消息模式" defaultChecked={actionType==='sendNow'} />

  


  //  markdown声明
  const mdParser=new MarkdownIt(); //markdown-it
  const mdEditor=React.useRef(null); //markdown编辑器

  //声明markdown的插入图片方法
  const onCustomImageUpload = (event: any): Promise<any> => {
    // setImageUrl('')
    let tempUrl=''
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: '插入图片',
        destroyOnClose:true,
        content: <>
        <p>钉钉中图片链接提取方法：</p>
              <p>1.打开【手机钉钉】将图片发送或到任意一个会话中（会话中已存在他人发送的图片跳过该步骤）</p>
              <p>2.在【电脑端】将图片从钉钉会话拖拽到浏览器中</p>
              <p>3.复制图片地址</p>
              <p><a target="_black" href="https://static.dingtalk.com/media/lAHPDiCps86iYgvNA2zNBaA_1440_876.gif">点我查看教程</a></p>
              <Input onChange={(e)=>tempUrl=e.target.value} placeholder="请输入图片链接" />
        </>,
        onOk:()=>{
          resolve({ url: tempUrl });
        },
        onCancel:()=>{
          resolve({ url: '' });

        }
      })
      
    });
  }; //markdown 插入图片方法

  const handleEditorChange=({html, text})=> {    
    // console.log('handleEditorChange', html, text)
    let temp={...contentData}
    temp.text=text
    setContentData(temp)
  }



  MdEditor.unuse(Plugins.FontUnderline)
  MdEditor.unuse(Plugins.FontStrikethrough)
  MdEditor.unuse(Plugins.BlockWrap)
  MdEditor.unuse(Plugins.BlockCodeInline)
  MdEditor.unuse(Plugins.BlockCodeBlock)
  MdEditor.unuse(Plugins.Table)
  MdEditor.unuse(Plugins.ModeToggle)
  MdEditor.unuse(Plugins.FullScreen)


  
  // feedCard声明
  const initFormValonce=()=>{
    setInitFormVal(false)
  }



  //数据更新方法
  const dataSet=(data)=>{
    let temp=null;
    temp={...contentData};
      temp.msgtype=addType;
      switch(addType){
        case 'markdown':
          temp.isAtall=data[1].value
          temp.name=data[3].value ? data[0].value : data[2].value
          temp.title=data[0].value
          temp.text=mdEditor.current.getMdValue()
          break;
        case 'feedCard':
          let listtemp=[]
          // console.log(temp)
          temp.name=data[0].value!==undefined ? data[0].value : ''
          temp.links=[]//重置
          for(let i=1;i<data.length;i=i+3){
            if(i>=3&&data[i].name.length===1){
              i++;
            }
            if(i>data.length-1){
              break;
            }
            listtemp.push({
              "title":data[i].value!==undefined ? data[i].value : '',
              "picURL":data[i+1].value!==undefined ? data[i+1].value : '',
              "messageURL":data[i+2].value!==undefined ? data[i+2].value : '',
            })
          }
          temp.links=listtemp;
          break;
        case 'text':
          temp.name=data[0].value!==undefined ? data[0].value : ''
          temp.isAtall=data[1].value!==undefined ? data[1].value : false
          temp.text=data[2].value!==undefined ? data[2].value : ''
          break;
        default:
          break;
      }
      setContentData(temp)
  }
  
  

  //表单公共事件

  //监听简介是否与标题相同
  const jianjiecheckchange=(e)=>{
    // console.log(e.target.checked)
    setTitleboth(e.target.checked)
  }
  //监听是否@所有人
  const atcheckchange=(e)=>{
    // console.log(e.target.checked)
    setAtall(e.target.checked)
  }

  //监听表单字段更新
  const onFieldsChange= (changedFields, allFields)=>{
    // console.log(allFields)
    dataSet(allFields)
    // console.log(changedFields, allFields)
  }

  //表单提交事件
  const onFinish = async(values) => {
    let temp={...contentData}
    switch (addType) {
      case 'markdown':
        temp.text=mdEditor.current.getMdValue();
        if(mdEditor.current.getHtmlValue().length===0){
          message.error('消息内容不得为空')
          return null;
        }
        break;
      case 'feedCard':
        for(let i=0;i<temp.links.length;i++){
          // temp.links[i].messageURL='dingtalk://dingtalkclient/page/link?url='+temp.links[i].messageURL
          temp.links[i].messageURL='dingtalk://dingtalkclient/page/link?url='+encodeURIComponent(temp.links[i].messageURL)

        }
        break;
      case 'text':
        temp.links=null
        break;
      default:
        break;
    }
    // if(addType==='markdown'){
    //   temp.text=mdEditor.current.getMdValue();
    //   if(mdEditor.current.getHtmlValue().length===0){
    //     message.error('消息内容不得为空')
    //     return null;
    //   }
    // }else if(addType==='feedCard'){
    //   for(let i=0;i<temp.links.length;i++){
    //     temp.links[i].messageURL='dingtalk://dingtalkclient/page/link?url='+temp.links[i].messageURL
    //   }
    // }
    setLoading(true)
    
    // console.log(values)
    switch (actionType) {
      case 'add':
        await addContent(temp).then((res)=>{
          if(res.success){
            setCurrent(1)
          }
        })
        break;
      case 'edit':
        await updateContent(temp).then((res)=>{
          if(res.success){
            setCurrent(1)
          }
        })
        break;
      case 'sendNow':
        // console.log(checkRobot)
        if(checkRobot.length===0){
          message.error('请选择要发送消息的机器人')
          setLoading(false)
          return false;
        }
        temp.robotIds=checkRobot;
        await sendNow(temp).then((res)=>{
          if(res.success){
            message.success('任务执行完毕')
            Modal.info({
              title:'任务执行结果',
              width:500,
              content:
                <Table columns={column} dataSource={res.data.sendResult} rowKey="robotId" />
            })
          }else{
            message.error(res.message)
          }
        })
        break
      default:
        break;
    }
    
    setLoading(false)
  };

  const viewFoot=
  <Spin spinning={switchLoading}>
    {
      actionType==='sendNow' ?
        <Card style={{marginBottom:'10px',width:'calc(50% - 8px)'}} title="请勾选需要发送该任务的机器人">
          <Checkbox.Group onChange={RobotChange} defaultValue={checkRobot}>
            <Row>
            {
              robotList.map((item)=>{
                return(
                  <Col key={`robotc${item.id}`} span={8}>
                    <Checkbox value={item.id}>{item.robotsName}</Checkbox>
                  </Col>
                )
              })
            }
            </Row>
          </Checkbox.Group>
        </Card>
      : null
    }
    <Form.Item>
      <Button loading={loading} type="primary" htmlType="submit">
        { actionType!=='sendNow' ? '下一步' : '立即发送' }
      </Button>

    </Form.Item>
    </Spin>
    


  // let markdownPage;
  // let feedCardPage;
  // let TextPage;
  let ShowPage;

  if(addType==='markdown'){
    ShowPage=
    <Form layout="vertical" name="nest-messages" onFinish={onFinish} onFieldsChange={onFieldsChange} wrapperCol={{ span: 10 }}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="title" label="标题："
            initialValue={contentData.title}
            wrapperCol={{span:24}}
            rules={[
              { required: true ,message:'标题不能为空'},
              ]}>
            <Input maxLength={255}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="atall"
            label="@设置："
            valuePropName="checked"
            initialValue={contentData.isAtall}
            wrapperCol={{span:24}}
          >
            <Checkbox value={atall} onChange={atcheckchange}>
              @所有人（群内需要关闭【仅群主和群管理员可@所有人】，否则发送失败）
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="jianjie" label="简介（仅自己可见）："
            initialValue={contentData.name}
            wrapperCol={{span:24}}
            rules={
              !titleboth&&actionType!=='sendNow' ?[
              { required: true ,message:'简介不能为空'},
              ] :null}>
            <Input disabled={titleboth} maxLength={255}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="loadtitle"
            label="简介设置："
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox value={titleboth} onChange={jianjiecheckchange}>
              简介与标题相同
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" onClick={()=>{
          mdEditor.current.setText('# 一级标题\n\n**加粗**\n*斜体*\n***斜体和加粗***\n\n* 无序列表\n* 无序列表2\n\n1. 有序列表\n2. 有序列表2\n\n> 引用 **加粗** *斜体* ***斜体和加粗***\n\n公告链接：[我是标题，https://taobao.com](https://taobao.com)\n![](https://static.dingtalk.com/media/lAHPDiCps86iYgvNA2zNBaA_1440_876.gif)')
        }}
      >
        不会用？点我查看示例（会直接覆盖以下内容，请注意）
      </Button>

      <MdEditor
        ref={mdEditor}
        style={{ height: "500px",marginBottom:'20px' }}
        value={contentData.text}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        onCustomImageUpload={onCustomImageUpload}
        config={{
          view:{
            menu: true,
            md: true,
            html: true,
            hideMenu: true
          }
        }}
      />
    {viewFoot}
  </Form>
  }else if(addType==='feedCard'){
    ShowPage=
    <Form layout="vertical" name="nest-messages" onFinish={onFinish} onFieldsChange={onFieldsChange}  >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="jianjie" label="简介（仅自己可见）："
            initialValue={contentData.name}
            rules={
              actionType!=='sendNow' ?[
              { required: true ,message:'简介不能为空'},
              ] :null}>
            <Input maxLength={255}/>
          </Form.Item>
          <Card title="内容设置" style={{marginBottom:'10px'}}>
            <Form.Item name="title-frist" label="首图标题："
              initialValue={contentData.links.length>0 ?contentData.links[0].title :''}
              rules={[
                { required: true ,message:'标题不能为空'},
                ]}>
              <Input maxLength={255} />
            </Form.Item>
            <Form.Item name="imgulr" label="图片链接："
              initialValue={contentData.links.length>0 ?contentData.links[0].picURL :''}
              rules={[
                { required: true ,message:'图片链接不能为空'},
                { type: 'url' ,message:'请输入正确的图片链接'},
                ]}>
              <Input />
            </Form.Item>
            <Form.Item name="link" label="跳转链接："
              initialValue={  contentData.links.length>0 ?contentData.links[0].messageURL : ''}
              rules={[
                { required: true ,message:'链接不能为空'},
                { type: 'url' ,message:'请输入正确的链接'},
                ]}>
              <Input />
            </Form.Item>
            <Form.List name="feedCardSecond">
              {(fields, { add, remove }) => {
                if(fields.length+1<contentData.links.length&&actionType==='edit'&&initFormVal){
                  initFormValonce()
                  for(let i=0;i<contentData.links.length-1;i++){
                    fields.push(
                      {
                        fieldKey: i,
                        isListField: true,
                        key: i,
                        name: i,
                      }
                    )
                  }
                }
                return(
                  <>
                    {fields.map(field => (
                      <Row gutter={8} justify="space-between" key={field.key} style={{alignItems:'center'}}>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            label="标题"
                            name={[field.name, 'title']}
                            fieldKey={[field.fieldKey, 'title']}
                            rules={[{ required: true, message: '标题不能为空' }]}
                            initialValue={field.key+1<contentData.links.length ? contentData.links[field.key+1].title : ''}
                          >
                            <Input maxLength={255} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            label="图片链接"
                            name={[field.name, 'imgulr']}
                            fieldKey={[field.fieldKey, 'title']}
                            rules={[
                              { required: true, message: '标题不能为空' },
                              { type: 'url', message: '请输入正确的图片链接' }
                            ]}
                            initialValue={field.key+1<contentData.links.length ? contentData.links[field.key+1].picURL : ''}
                          >
                            <Input maxLength={255} />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...field}
                            label="跳转链接"
                            name={[field.name, 'link']}
                            fieldKey={[field.fieldKey, 'link']}
                            rules={[
                              { required: true, message: '链接不能为空' },
                              { type: 'url', message: '请输入正确的链接' }
                            ]}
                            initialValue={field.key+1<contentData.links.length ? contentData.links[field.key+1].messageURL : ''}
                          >
                            <Input maxLength={255} />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <MinusCircleOutlined onClick={() => {console.log(field);remove(field.name)}} />
                        </Col>

                      </Row>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        添加更多
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
            
          </Card>
        </Col>
        <Col span={12}>
          <Card className="preview-feedCard" title="预览">
            <FeedCardPreview type={actionType} data={contentData}></FeedCardPreview>
          </Card>
        </Col>
      </Row>

      {viewFoot}
    </Form>
  }else if(addType==='text'){
    ShowPage=
    <Form layout="vertical" name="nest-messages" onFinish={onFinish} onFieldsChange={onFieldsChange}  >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="jianjie" label="简介（仅自己可见）："
            initialValue={contentData.name}
            rules={actionType!=='sendNow' ?[
              { required: true ,message:'简介不能为空'},
              ] :null}>
            <Input disabled={titleboth} maxLength={255}/>
          </Form.Item>
          <Form.Item
            name="atall"
            label="@设置："
            valuePropName="checked"
            wrapperCol={{span:24}}
            initialValue={contentData.isAtall}
          >
            <Checkbox value={atall} onChange={atcheckchange}>
              @所有人（群内需要关闭【仅群主和群管理员可@所有人】，否则发送失败）
            </Checkbox>
          </Form.Item>
          <Form.Item name="content" label="内容："
            initialValue={contentData.text}
            rules={[
              { required: true ,message:'内容不能为空'},
              ]}>
            <TextArea autoSize={{minRows:2}}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Card className="preview-feedCard" title="预览">
            <TextPreview type={actionType} data={contentData}></TextPreview>
          </Card>
        </Col>
      </Row>

      {viewFoot}
    </Form>
  }

  const steps = [
    {
      title: '消息设置',
      content: 
        <div className="input-view">
          <div className="info">
            <Card title="消息设置">
              {ShowPage}


            </Card>
          </div>
        </div>
    },
    {
      title: '完成',
      content: 
        <Result
        status="success"
        title="添加成功"
        subTitle="消息添加成了哦～"
        extra={[
          <Button key="login" type="primary" onClick={()=>{history.replace('/system/robot/content')}}>
            去查看
          </Button>,
        ]}
      />
      ,
    },
  ];

  return(
    <div className="robot-add">
      <div className="add-view">
        
      <PageHeader
        style={{padding:0}}
        className="site-page-header"
        onBack={() => history.goBack()}
        title="添加消息"
        subTitle={
          actionType!=='edit'&&current===0 ? switchModel : null
        }
      />
        <div className="view-header">
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className="view-content">
          {steps[current].content}
        </div>
        
      </div>
    </div>
  )
  
}
export default ContentAdd