import React, { useState, useMemo } from 'react';
import '../../../css/robotList.css'
import { useHistory } from 'react-router-dom';
import { Table, Spin, Tooltip, Space, Popconfirm, message } from 'antd';
import { getContentList, delContent } from '../../../api/index'
import ContentDrawer from '../Drawer/ContentDrawer';
import { RobotContentContext } from '../../../store/context'

function ContentListCom(props) {
  console.log(props)

  let history = useHistory();

  const [showType, setShowType] = useState('nomal')

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [visible, setVisible] = useState(false)

  const [contentDetail, setContentDetail] = useState(null)


  const getList = async () => {
    setLoading(true)
    await getContentList().then((res) => {
      if (res.success) {
        setData(res.data)
      }
    })
    setLoading(false)
  }

  const onSelectChange = selectedRowKeys => {
    console.log(selectedRowKeys)
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    props.selAction(selectedRowKeys[0])
  };

  const contentDel = async (id) => {
    setLoading(true)
    await delContent({ id: id }).then((res) => {
      if (res.success) {
        message.success('删除成功')
      }
      getList()
    })
  }
  const setDetail = (text, record) => {
    setContentDetail(record)
    setVisible(true)
  }


  useMemo(() => {
    getList();
    if (props.type) {
      setShowType(props.type)
    }
  }, [])

  let columns = [];
  let rowSelection = null

  switch (showType) {
    case 'sel':
      rowSelection = {
        type: 'radio',
        selectedRowKeys: [props.sel],
        onChange: onSelectChange,
      }

      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 200,
        },
        {
          title: '消息简介',
          dataIndex: 'name',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`简介：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          }
        },
        {
          title: '消息类型',
          dataIndex: 'msgtype',
          width: 100
        },
        {
          title: '@所有人',
          dataIndex: 'isAtall',
          align: 'center',
          render: val => {
            return (
              val ? '是' : '否'
            )
          },
          width: 100
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`添加时间：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '最后修改时间',
          dataIndex: 'updateTime',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`最后修改时间：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'right',
          render: (text, record) => {
            return <a onClick={() => setDetail(text, record)}>预览</a>
          }
        },
      ];
      break;

    default:
      columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100
        },
        {
          title: '消息简介',
          dataIndex: 'name',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`简介：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          }
        },
        {
          title: '消息类型',
          dataIndex: 'msgtype',
          width: 100
        },
        {
          title: '@所有人',
          dataIndex: 'isAtall',
          align: 'center',
          render: val => {
            return val ? '是' : '否'
          },
          width: 100
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`添加时间：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '最后修改时间',
          dataIndex: 'updateTime',
          ellipsis: true,
          render: tips => {
            return (
              <Tooltip placement="topLeft" title={`最后修改时间：\n${tips}`}>
                {tips}
              </Tooltip>
            )
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'right',
          render: (text, record) => {
            return (
              <Space>
                <a onClick={() => setDetail(text, record)}>预览</a>
                <a onClick={() => {
                  // console.log(record)
                  history.push({ pathname: '/system/robot/contentadd', query: { type: 'edit', msgtype: record.msgtype, data: record } })
                }}>
                  编辑
                </a>
                <Popconfirm
                  placement="topRight"
                  overlayStyle={{ minWidth: '200px' }}
                  title="你确定要删除这条消息吗？"
                  onConfirm={() => contentDel(record.id)}
                  // onCancel={cancel}
                  cancelText="取消"
                  okText="确定"
                >
                  <a className="robot-del">删除</a>
                </Popconfirm>
              </Space>
            )
          }
        },
      ];
      break;
  }


  return (
    <Spin spinning={loading}>
      <div className="table-data">
        {
          visible ?
            <RobotContentContext.Provider value={contentDetail}>
              <ContentDrawer pop={{
                state: [visible, setVisible],
              }
              } />
            </RobotContentContext.Provider>
            : null
        }
        <Table rowSelection={rowSelection} pagination={showType === 'sel' ? { defaultPageSize: 5 } : null} columns={columns} dataSource={data} rowKey="id" />
      </div>
    </Spin>
  )
}
export default ContentListCom