import React,{ useState,useContext} from 'react';
import { Form, Input, Button, Drawer, message } from 'antd';
import { RobotContext } from '../../../store/context'
import { editRbot } from '../../../api/index'

function RobotDrawer(props){
  let type =props.pop.type //类型
  const [visible,setVisible]=props.pop.state //刷新状态
  const [loading,setLoading]=useState(false) //刷新状态


  let robotContent=useContext(RobotContext);



  const { TextArea }=Input

  const edit=async(val)=>{
    await editRbot({
      id:robotContent.id,
      robotsName: val.name,
      webhook: val.webhook,
      secret: val.secret,
      remark: val.mark,
    }).then((res)=>{
      if(res.success){
        onClose()
        message.success('机器人信息修改成功')
      }
      // console.log(res)
    })
  }


  const onClose=()=>{
    setVisible(false)
  }


  const onFinish = async (values) => {
    setLoading(true)
    if(type==='edit'){
      await edit(values.robot)
    }
    // console.log(values)
    setLoading(false)
  }

  return(
    <Drawer
      width={400}
      title="机器人详情"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      {
        visible ?
          type==='edit' ?
        <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
          <Form.Item name={['robot', 'name']} label="机器人名称：" initialValue={robotContent.robotsName}
            rules={[
              { required: true ,message:'机器人名称不能为空'},
              ]}>
            <Input maxLength={15} />
          </Form.Item>
          <Form.Item name={['robot', 'webhook']} label="WebHook：" initialValue={robotContent.webhook} rules={[
            { required: true ,message:'WebHook不能为空'},
            { type: 'url' ,message:'请输入正确的WebHook'},
            { min: 114 ,message:'WebHook长度为114位'},
            ]}>
            <TextArea maxLength={114} autoSize />
          </Form.Item>
          <Form.Item name={['robot', 'secret']} label="加签：" initialValue={robotContent.secret} rules={[
            { required: true ,message:'加签不能为空'},
            { len: 67 ,message:'加签长度为67位'},

            ]}>
            <TextArea maxLength={67} autoSize />
          </Form.Item>
          <Form.Item name={['robot', 'mark']} label="备注：" initialValue={robotContent.remark} rules={[

            ]}>
              <Input maxLength={20} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form> : 

          type==='detail' ?

          <Form layout="vertical" name="nest-messages">
            <Form.Item name={['robot', 'name']} label="机器人名称：">
              <p>{robotContent.robotsName}</p>
            </Form.Item>
            <Form.Item name={['robot', 'webhook']} label="WebHook：">
              <p>{robotContent.webhook}</p>
            </Form.Item>
            <Form.Item name={['robot', 'secret']} label="加签：">
              <p>{robotContent.secret}</p>
            </Form.Item>
            <Form.Item name={['robot', 'mark']} label="备注：">
              <p>{robotContent.remark}</p>
            </Form.Item>
          </Form>

          :null
        :null
      }
      
    </Drawer>
  )
}
export default RobotDrawer