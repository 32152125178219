import React,{useContext,useMemo,useState} from 'react';
// import {BrowserRouter as Router,Redirect,Route,Switch} from 'react-router-dom'
import {HashRouter as Router,Redirect,Switch} from 'react-router-dom'
import authRoutes from './routes/authRoutes';
import AuthRoute from './routes/AuthRoute'
import { UserContext } from './store/context';
import { outUserInfo, putUserInfo } from './store/pubtype';
import { Spin } from 'antd';
import Cookie from 'js-cookie'
import './App.css'

import {getSysUserInfo} from './api/index'
// import Login from './view/Login/Login';
// import Register from './view/Regist/Regist';
// import LayoutUI from './view/Layout/system'
function App() {
  const [loginCheck,setloginCheck]= useState(false)
  const [autoLogin,setautoLogin]= useState(false)
  const {userInfo,dispatch:userDispatch}=useContext(UserContext)
  const getuinfo=async ()=>{
    // Cookie.set('userAuth','123')
    if(Cookie.get('userAuth')){
      setautoLogin(true)
      await getSysUserInfo().then((res)=>{
        if(res.success){
          userDispatch({type:putUserInfo,value:res.data})
        }else{
          userDispatch({type:outUserInfo,value:null})
        }
      })
      setautoLogin(false)
    }
    setloginCheck(true)
  }
  useMemo(getuinfo,[])
  
  
  return(
    <Spin spinning={autoLogin} style={{minHeight:'100%'}} tip="加载中">
      <div id="app">
      {
        loginCheck && 
        <Router>
          <Switch>
            <Redirect from="/" exact to="/system" />
              {
                authRoutes.map((route)=> <AuthRoute key={route.path} userInfo={userInfo} {...route} />
                )
              }
              <Redirect to="/system/dashboard" />
              {/* <Redirect to="" /> */}
          </Switch>
            
        </Router>
      }
     
      </div>
      </Spin>
  )
}

export default App