import axios from "axios";
import Cookie from 'js-cookie'
// import {publicIp} from './apiIp'
// import {LOGIN} from './apiURL'
import {message} from 'antd'
// import Modal from "antd/lib/modal/Modal";
// import { useHistory } from "react-router-dom";

// let hide = null
// axios.defaults.baseURL='http://192.168.31.238:9001'
// axios.defaults.baseURL='https://lazyoa.plug-in.top'
axios.defaults.baseURL='https://lazyoa.api.lazyoa.com'
// axios.defaults.baseURL='http://zjyjstgbc.oicp.net'



axios.defaults.timeout=5000
// const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
//     // timeout: 10000, // 设置超时时间10s
//     baseURL: 'http://192.168.31.238:8888/'   //根据自己配置的反向代理去设置不同环境的baeUrl
// })
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
// instance.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'


let httpCode = {        //这里我简单列出一些常见的http状态码信息，可以自己去调整配置
    400: '请求参数错误',
    401: '登陆过期, 请重新登录',
    403: '服务器拒绝本次访问',
    404: '请求资源未找到',
    500: '内部服务器错误',
    501: '服务器不支持该请求中使用的方法',
    502: '网关错误',
    504: '网关超时'
}
// let history=useHistory()
/** 添加请求拦截器 **/
axios.interceptors.request.use(config => {
    config.headers['token'] = Cookie.get('userAuth')
    // hide = message.loading({content: 'Loading...', duration: 0});
    // 在这里：可以根据业务需求可以在发送请求之前做些什么:例如我这个是导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob，就可以在此处设置。
    if (config.url.includes('pur/contract/export')) {
        config.headers['responseType'] = 'blob'
    }
    // 我这里是文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
    if (config.url.includes('pur/contract/upload')) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

/** 添加响应拦截器  **/
axios.interceptors.response.use(response => {
    // hide()
    // console.log(response)
    // if (response.statusText === 'ok') {     // 响应结果里的statusText: ok是我与后台的约定，大家可以根据实际情况去做对应的判断
    if (response.data.success) {     // 响应结果里的statusText: ok是我与后台的约定，大家可以根据实际情况去做对应的判断
        return Promise.resolve(response.data)
    } else {
        // message.error(response.data.message)
        return Promise.reject(response.data)
        // return Promise.reject(response.data)
    }
}, error => {
    // hide()
    if (error.response) {
        // 根据请求失败的http状态码去给用户相应的提示
        let tips = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
        message.error(tips)
        if (error.response.status === 401) {    // token或者登陆失效情况下跳转到登录页面，根据实际情况，在这里可以根据不同的响应错误结果，做对应的事。这里我以401判断为例
            //针对框架跳转到登陆页面
            // TokenOut()
            // history.push('/login');
            setTimeout(() => {
                Cookie.remove('userAuth')
                // window.location.href="http://localhost:3000"
                window.location.reload()
            }, 2000);
        }
        return Promise.reject(error)
    } else {
        // message.error('请求超时, 请刷新重试')
        return Promise.reject({message:'网络开小差了，请检查网络'})
    }
})


export default function request (api, params = {}, type = 'GET', config = {}){
  const data = (type === 'GET') ? 'params' : 'data';
  return new Promise((resolve, reject) => {
    axios({
      url: api,
      method:type,
      [data]: params,
    }).then(response => {
        resolve(response)
      })
      .catch(error => {
        // console.dir(error);
        message.error(error.message)
        // message.error(typeof error.response.data === 'string' ? error.response.data : JSON.stringify(error.response.data));
        resolve(error);
      });
  });
};

// /* 统一封装get请求 */
// export const get = (url, params, config = {}) => {
//     return new Promise((resolve, reject) => {
//         instance({
//             method: 'get',
//             url,
//             params,
//             ...config
//         }).then(response => {
//             resolve(response)
//         }).catch(error => {
//             reject(error)
//         })
//     })
// }

// /* 统一封装post请求  */
// export const post = (url, data, config = {}) => {
//     return new Promise((resolve, reject) => {
//         instance({
//             method: 'post',
//             url,
//             data,
//             ...config
//         }).then(response => {
//             resolve(response)
//         }).catch(error => {
//             reject(error)
//         })
//     })
// }